import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Config from '../Config';
import './EmbedPage.css'; // CSSファイルをインポート（必要に応じて作成）

function EmbedPage() {
  const { id } = useParams();
  const embedUrl = Config[`ID${id}`];
  const [mintAmount, setMintAmount] = useState(1);

  const incrementMintAmount = () => {
    setMintAmount(mintAmount + 1);
  };

  const decrementMintAmount = () => {
    if (mintAmount > 1) {
      setMintAmount(mintAmount - 1);
    }
  };

  // 支払いURLを動的に生成する（例示のために仮のURLを使用しています）
  const paymentUrl = `${Config.PAYMENT_URL}&tokenId=${id}&quantity=${mintAmount}`;

    // トップに戻る関数
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

  return (
    <div className='main-contents'>

    <button className="scrollTopButton" onClick={scrollToTop}>
      トップへ戻る
    </button>

      <iframe
        src={embedUrl}
        title={`Embedded Content ${id}`}
        width="600px"
        height="600px"
        style={{ maxWidth: '100%' }}
        frameborder="0"
      ></iframe>

    <div className="mint-container">


      <div className="mint-controls">
        <button className="controls-button" onClick={decrementMintAmount}>-</button>
        <span className='mint-amount'>{mintAmount}</span>
        <button className="controls-button" onClick={incrementMintAmount}>+</button>
      </div>

      <div className='credit-card-container'>
        <a href={paymentUrl} rel="noopener noreferrer" className="credit-card-button">
          クレジットカード決済
        </a>
      </div>
      </div>

    </div>

  );
}

export default EmbedPage;
