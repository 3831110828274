// components/IframeSlider.js
import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import './IframeSlider.css'; // CSSファイルのインポート

const images = [
  { src: '1.jpg', title: 'オカメインコ', tokenId: '0', url: '/embed/0' },
  { src: '2.jpg', title: 'ヒツジ', tokenId: '1', url: '/embed/1' },
  { src: '3.jpg', title: 'プードル', tokenId: '2', url: '/embed/2' },
  { src: '4.jpg', title: 'ハリネズミ', tokenId: '3',url: '/embed/3' },
  { src: '5.jpg', title: 'ウサギ', tokenId: '4',url: '/embed/4' },
  { src: '6.jpg', title: 'サル', tokenId: '5',url: '/embed/5' },
  { src: '7.jpg', title: 'タヌキ', tokenId: '6',url: '/embed/6' },
  { src: '8.jpg', title: 'ネコ', tokenId: '7',url: '/embed/7' },
  { src: '9.jpg', title: 'ゾウ', tokenId: '8',url: '/embed/8' },
  { src: '10.jpg', title: 'ネズミ', tokenId: '9',url: '/embed/9' },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "red",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        zIndex: 25,
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        lineHeight: "50px",
        textAlign: "center",
        fontSize: "20px" // フォントサイズを大きくしてアイコンを中央に表示
      }}      
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "green",
        borderRadius: "50%",
        width: "50px", // 幅を50pxに
        height: "50px", // 高さを50pxに
        zIndex: 25,
        left: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        lineHeight: "50px", // lineHeightも50pxに
        textAlign: "center",
        fontSize: "20px" // フォントサイズを20pxに
      }}
      onClick={onClick}
    />
  );
}


const IframeSlider = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 640);
  const sliderRef = useRef(); // スライダーへの参照

    // 画面サイズに応じてビューを切り替える
    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(window.innerWidth < 640);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const showNextImage = () => {
      setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
  
    const showPreviousImage = () => {
      setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    swipeToSlide: true,
    variableWidth: true,
    centerMode: true, // 中央に表示するモードを無効に
  
    centerPadding: '50px', // 中心に来るスライドの両サイドのパディングを調整
  
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          variableWidth: true,
          centerMode: true,
        }
      }
    ],

      // スライドが変更されたときに呼ばれる関数
      afterChange: (index) => {
        // 現在の画像のインデックスを更新
        setSelectedImageIndex(index % images.length);
      }
    };

// クリックしたカードが中心に来るようにする
const handleCardClick = (index) => {
  setSelectedImageIndex(index);
  sliderRef.current.slickGoTo(index); // スライダーを指定の位置に移動
};

    return (
      <div>
      <div className="slider-container">

        {/* PC表示時の画像切り替えボタン */}
        {!isMobileView && (
          <button className="slider-button prev" onClick={showPreviousImage}>&lt;</button>
        )}

      <div className="selected-image-container">
        <div className='selected-card'>
          <p>Token ID: {images[selectedImageIndex].tokenId}</p>
          <img src={`/${images[selectedImageIndex].src}`} alt={`Selected ${images[selectedImageIndex].title}`} />
          <h3>{images[selectedImageIndex].title}</h3>
          <button className='mint-button' onClick={() => window.location.href = images[selectedImageIndex].url}>ミントページへ</button>
        </div>
      </div>

        {!isMobileView && (
          <button className="slider-button next" onClick={showNextImage}>&gt;</button>
        )}

      </div>


      {isMobileView && (
        <p className="mobile-instruction">下のボタンで切り替わるよ！</p>
      )}
  
        {isMobileView ? (
        <div className="mobile-slider">
        <button className="slider-button prev" onClick={showPreviousImage}>&lt;</button>
        <button className="slider-button next" onClick={showNextImage}>&gt;</button>
      </div>
        ) : (
          <div className="iframe-slider">
            <Slider ref={sliderRef} {...sliderSettings}>
              {images.map((image, index) => (
              <div key={index} className={`card ${selectedImageIndex === index ? 'selected' : ''}`} onClick={() => handleCardClick(index)}>
              <div className="card-content">
                    <p>Token ID: {image.tokenId}</p>
                    <span className="title">{image.title}</span>
                  </div>
                  <img src={`/${image.src}`} alt={`Slide ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    );
  };
  
  export default IframeSlider;
