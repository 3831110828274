import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import IframeSlider from './components/IframeSlider';
import EmbedPage from './components/EmbedPage';

import './App.css';

const sectionStyle = {
  backgroundImage: `url(/bg.jpg)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed'
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  zIndex: -1
};

const contentStyle = {
  position: 'relative',
  zIndex: 2
};

function PageContent() {
  const location = useLocation();
  const navigate = useNavigate(); // useNavigateフックを追加
  const isHomePage = location.pathname === '/';

  return (
    <>
      {isHomePage ? (
        <h1>10種類のNFT</h1>
      ) : (
        <div className="backToTopContainer">
        <button onClick={() => navigate('/')} className="backToTopButton">
          トップに戻る
        </button>
      </div>
      )}
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="App" style={{ ...sectionStyle, ...contentStyle }}>
        <div style={overlayStyle}></div>
        <Header />

        <PageContent />

        <Routes>
          <Route path="/embed/:id" element={<EmbedPage />} />
          <Route path="/" element={<IframeSlider />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
